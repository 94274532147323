<template>
  <div class="md:mb-0 mb-8 md:block hidden">
    <div class="footer-section-header">
      {{ props.title }}
    </div>
    <slot></slot>
  </div>
  <div class="mb-8 block md:mb-0 md:hidden">
    <Disclosure v-slot="{ open }" default-open>
      <DisclosureButton class="flex w-full justify-between">
        <div class="footer-section-header">
          {{ props.title }}
        </div>
        <ChevronUpIcon
          :class="open ? '' : 'rotate-180 transform'"
          class="h-5 w-5"
        />
      </DisclosureButton>
      <DisclosurePanel>
        <slot></slot>
      </DisclosurePanel>
    </Disclosure>
  </div>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronUpIcon } from '@heroicons/vue/20/solid'

const props = defineProps({
  title: {
    type: String,
    default: ''
  }
})
</script>

<style lang="postcss" scoped>
.footer-section-header {
  @apply font-bold text-sm uppercase md:mb-6 mb-[14px];
}
</style>
