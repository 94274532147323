<template>
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.9144 14.9825L18.75 18.75M17.4467 9.20455C17.4467 13.5977 13.821 17.1591 9.34836 17.1591C4.87576 17.1591 1.25 13.5977 1.25 9.20455C1.25 4.81137 4.87576 1.25 9.34836 1.25C13.821 1.25 17.4467 4.81137 17.4467 9.20455Z"
      stroke="currentColor"
      stroke-width="1.7"
      stroke-linecap="round"
    />
  </svg>
</template>
