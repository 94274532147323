export const useLang = () => {
  const i18n = useI18n()

  const lang = computed({
    get() {
      if (i18n) {
        return i18n.locales.value.find(v => v.code === i18n.locale.value)
      }
      return false
    },
    set(selectedLang) {
      i18n.setLocale(selectedLang.code)
    }
  })

  return lang
}
