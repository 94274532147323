<template>
  <div class="nav-mobile-container">
    <AppHeaderMenuMobile ref="headerMenu" />

    <button class="nav-mobile-link" @click="openMenuCatalog">
      <IconsCategory class="nav-mobile-link--icon" />
      <p class="nav-mobile-link--subtitle">
        {{ t('Каталог') }}
      </p>
    </button>
    <AppHeaderMobileSearch class="nav-mobile-link cursor-pointer">
      <IconsSearch class="nav-mobile-link--icon" />
      <p class="nav-mobile-link--subtitle">
        {{ t('Пошук') }}
      </p>
    </AppHeaderMobileSearch>

    <NuxtLink
      v-if="!authStore.loggedInStore"
      :to="localePath('/cabinet')"
      class="nav-mobile-link"
    >
      <IconsUserCircle v-if="bottom" class="nav-mobile-link--icon" />
      <IconsUser v-else class="nav-mobile-link--icon" />
      <p class="nav-mobile-link--subtitle">
        {{ authStore.loggedIn ? t('Кабінет') : t('Увійти') }}
      </p>
    </NuxtLink>
    <div v-else class="py-3">
      <span
        id="sign-out-store-btn"
        class="flex flex-col items-center gap-2 nav-mobile-link cursor-pointer w-fit hover:text-orange"
        @click="authStore.signOutStore"
      >
        <ArrowRightOnRectangleIcon class="h-5 text-green-ibis" />
        <p class="nav-mobile-link--subtitle">{{ t('Вийти') }}</p>
      </span>
    </div>

    <NuxtLink :to="localePath('/cabinet/favorites')" class="nav-mobile-link">
      <IconsHeart class="nav-mobile-link--icon" />
      <p class="nav-mobile-link--subtitle">
        {{ t('Обране') }}
      </p>
    </NuxtLink>

    <button class="nav-mobile-link" @click="cartStore.openCart">
      <IconsCart
        class="nav-mobile-link--icon"
        :count="cartStore.countItemsInCart"
      />
      <p class="nav-mobile-link--subtitle">
        {{ t('Кошик') }}
      </p>
    </button>
  </div>
</template>

<script setup>
import { ArrowRightOnRectangleIcon } from '@heroicons/vue/24/outline'
import { useCartStore } from '~~/stores/cart'
import { useAuthStore } from '~~/stores/auth'

const { t } = useI18n()

defineProps({
  bottom: {
    type: Boolean,
    default: false
  },
  isShowSearch: {
    type: Boolean,
    default: false
  },
  setIsShowSearch: {
    type: Function,
    default: () => {}
  }
})

const cartStore = useCartStore()
const authStore = useAuthStore()

const headerMenu = ref(null)

const open = ref(false)
const menu = ref('main')
provide('isOpenMenu', open)
provide('menuType', menu)

const openMenuCatalog = () => {
  open.value = true
  menu.value = 'catalog'
}

watch(
  () => useRoute().fullPath,
  () => {
    open.value = false
  }
)
watch(open, () => {
  if (open.value === false) {
    setTimeout(() => {
      menu.value = 'main'
    }, 500)
  }
})
</script>

<style lang="postcss" scoped>
.nav-mobile-container {
  @apply container py-3 flex lg:hidden items-center gap-2.5 xs:gap-[22px] text-green-evergreen text-sm justify-between;
}

.nav-mobile-link {
  @apply flex flex-col items-center gap-1;
}

.nav-mobile-link--icon {
  @apply h-5 w-5 text-green-ibis;
}

.nav-mobile-link--subtitle {
  @apply text-[10px] leading-[10px] 2xs:inline-block hidden;
}
</style>
