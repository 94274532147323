<template>
  <div v-if="storeTypes.isHunting" class="relative lg:mb-[33px] mt-[15px]">
    <a href="https://ibis.net.ua/post/odyag-dlya-viyskovih/">
      <div
        class="flex justify-center items-center bg-[#555845] h-[46px] lg:h-[33px] top-banner-mob"
      >
        <!--      <div class="text-center text-xs absolute text-white font-medium leading-3 max-w-[550px]">
            Увага! Відбувається оновлення функціоналу кабінету користувача. Працюємо задля комфорту наших клієнтів!
          </div>-->
      </div>
    </a>
  </div>
</template>

<script setup>
const storeTypes = useStoreType()
</script>

<style scoped>
.top-banner-mob {
  background-image: url('/images/top-banner.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 205;
  max-width: 1520px;
  width: 100%;
  margin: 0 auto;
}

@media (max-width: 1024px) {
  .top-banner-mob {
    height: 40px;
    background-size: cover;
  }
}

@media (max-width: 1024px) and (max-height: 1366px) {
  .top-banner-mob {
    height: 20px;
    background-size: cover;
  }
}

@media (max-width: 768px) {
  .top-banner-mob {
    height: 25px;
    background-size: contain;
  }
}
</style>
