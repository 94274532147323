<template>
  <swiper
    :key="sliderKey"
    :autoplay="{
      delay: 1000,
      disableOnInteraction: false
    }"
    :loop="true"
    :slides-per-view="10"
    watch-slides-progress
    :modules="[SwiperVirtual, SwiperAutoplay]"
    :breakpoints="{
      600: {
        slidesPerView: 8
      },
      900: {
        slidesPerView: 9
      },
      1050: {
        slidesPerView: 10
      },
      1200: {
        slidesPerView: 11
      },
      1350: {
        slidesPerView: 12
      },
      1500: {
        slidesPerView: 13
      }
    }"
    @active-index-change="onActiveIndexChange"
  >
    <swiper-slide
      v-for="(brand, index) in brands"
      :key="uniqueId(`${index}-`)"
      :virtual-index="index"
    >
      <nuxt-link
        class="border-gray border border-r-0 first:border-r relative h-12 w-full hover:cursor-pointer bg-white flex grayscale"
        :to="
          brand?.channelHostname
            ? `https://${brand?.channelHostname}${localePath(
              '/brand/' + brand?.slug
            )}`
            : localePath('/brand/' + brand?.slug)
        "
      >
        <lazy-nuxt-img
          v-if="isShown && (index < activeIndex || index - activeIndex < 14)"
          :src="brand.getImagePath()"
          loading="lazy"
          fit="contain"
          class="object-scale-down h-auto m-auto max-h-full max-w-full"
          height="30"
        />
      </nuxt-link>
    </swiper-slide>
  </swiper>
</template>

<script setup>
import uniqueId from 'lodash/uniqueId'
import { useNow } from '@vueuse/core'
import { useMenuStore } from '~/stores/menu'
import TaxonEntity from '~~/entities/Taxon.entity'
import BrandEntity from '~~/entities/Brand.entity'
import { useBrandStore } from '~/stores/brand'

import 'swiper/css'
import 'swiper/css/virtual'

// const isMounted = useMounted()
const sliderKey = ref(useNow().value)
const props = defineProps({
  categoryId: {
    type: Number,
    default: null
  },
  isShown: {
    type: Boolean,
    default: false
  }
})
const brandStore = useBrandStore()

const category = computed(
  () => new TaxonEntity(useMenuStore().findCategoryById(props.categoryId))
)

const brandList = computed(() => brandStore.brandList)

const brands = computed(() =>
  brandList.value[category.value.code]
    ? brandList.value[category.value.code].map(brand => new BrandEntity(brand))
    : []
)

const activeIndex = ref(0)

const onActiveIndexChange = swiper => {
  activeIndex.value = swiper.activeIndex
}

// onMounted(() => {
//   console.log('category.value.code', category.value.code)
//   return brandStore.fetchBrand(category.value.code)
// })
useAsyncData(
  'header-taxon-brand-' + category.value?.code,
  () => brandStore.fetchBrand(category.value.code),
  {
    server: false,
    lazy: true
  }
)
</script>
