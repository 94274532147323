<template>
  <div class="h-full overflow-y-auto bg-white py-2.5 flex flex-col">
    <div class="px-2.5">
      <DialogTitle
        as="a"
        class="flex justify-start items-center gap-2.5 border-b h-[57px] px-[11px] hover:text-primary hover:cursor-pointer"
        @click="handleBack"
      >
        <ArrowLeftIcon class="h-3.5" />
        {{ t('Порівняння') }}
      </DialogTitle>
    </div>
    <nav aria-label="Sidebar" class="relative text-sm flex-1 px-2.5 flex">
      <ul class="uppercase flex flex-col flex-1 divide-y-px">
        <template v-for="item in comparisonTaxons" :key="item.code">
          <li>
            <NuxtLink
              :to="localePath(`/compare?t=${item.code}`)"
              class="flex items-center justify-between h-10 gap-3.5 px-[11px] hover:text-primary"
            >
              <!-- <component
                :is="item.icon"
                v-if="item.icon"
                class="h-5 w-5 text-gray-mud"
              />
              <AppExternalSvgIcon
                v-else-if="item?.getImageUrl && item?.getImageUrl()"
                class="max-w-[20px] max-h-[20px] w-5"
                :path="item?.getImageUrl()"
              /> -->

              {{ item.name }}
              <span class="text-primary mr-auto">{{ item.count }}</span>
              <ChevronRightIcon class="h-3.5" />
            </NuxtLink>
          </li>
        </template>
      </ul>
    </nav>
  </div>
</template>

<script setup>
import { DialogTitle } from '@headlessui/vue'
import { ChevronRightIcon } from '@heroicons/vue/24/outline'
import { ArrowLeftIcon } from '@heroicons/vue/24/solid'
import { storeToRefs } from 'pinia'
import { useComparisonStore } from '~/stores/comparison'

const { t } = useI18n()
const comparisonStore = useComparisonStore()
const { comparisonTaxons } = storeToRefs(comparisonStore)

const emits = defineEmits('back')

// const navigation = computed(() => [])

const handleBack = () => {
  emits('back')
}
</script>
