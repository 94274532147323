import Collection from '~/entities/Collection'
export const useBrandStore = defineStore({
  id: 'brand-store',
  state: () => {
    return {
      list: {}
      // requestedList: []
    }
  },
  actions: {
    async fetchBrand(categoryCode) {
      if (this.list?.[categoryCode]?.length) {
        return this.list?.[categoryCode]
      }
      const res = await $fetch(`/api/brands-by-taxon/${categoryCode}`)
      // console.log(res)
      const data = res ? new Collection(res)?.data : []
      this.list[categoryCode] = [...data]?.slice(0, 25)
      return this.list[categoryCode]
    }
  },
  getters: {
    brandList: state => state.list
  }
})
