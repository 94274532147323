<template>
  <div
    :key="props.getValue(modelValue)"
    class="flex items-center border p-1 justify-between rounded-[3px] border-gray"
  >
    <template v-for="option in props.options" :key="props.getValue(option)">
      <a
        href="#"
        class="btn px-[11px] py-2 text-[10px] leading-2.5"
        :class="{
          'btn-primary': props.getValue(option) === props.getValue(modelValue)
        }"
        @click="
          () =>
            props.getValue(option) !== props.getValue(modelValue) &&
            changeValue(option)
        "
      >{{ props.getLabel(option) }}</a>
    </template>

    <!-- <a href="#" class="btn">РИБАЛКА</a> -->
  </div>
</template>

<script setup>
import get from 'lodash/get'

const props = defineProps({
  options: {
    type: Array,
    default: () => []
  },
  modelValue: { type: [String, Object], default: '' },
  getLabel: {
    type: Function,
    default: option => get(option, 'label', option)
  },
  getValue: {
    type: Function,
    default: option => get(option, 'value', option)
  }
})

const emits = defineEmits(['update:modelValue'])

const changeValue = option => {
  emits('update:modelValue', option)
}
</script>
