<template>
  <div class="flex flex-col rounded-md flex-1">
    <form class="flex" @submit="onSubmitSearch">
      <div
        ref="target"
        class="flex flex-grow items-stretch transition"
        :class="{
          'z-[205]': isFocused,
          'absolute w-full left-0': isFocused,
          relative: !isFocused
        }"
      >
        <div class="w-full">
          <div class="w-full z-10">
            <div
              class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 z-20"
            >
              <MagnifyingGlassIcon
                class="h-5 w-5 text-green-swamp"
                aria-hidden="true"
              />
            </div>
            <input
              id="search"
              ref="inputRef"
              v-model.trim="query"
              name="search"
              type="search"
              maxlength="70"
              class="block w-full rounded-none border-0 py-1.5 pl-10 placeholder-gray-mud focus:ring-0 sm:text-sm sm:leading-6"
              :class="[
                inputClass,
                isFocused
                  ? 'bg-white rounded-b-[0px] rounded-t-[5px]'
                  : 'bg-white md:bg-gray rounded-l-[5px]'
              ]"
              :placeholder="t('Пошук')"
              @focus="isFocused = true"
              @click="isFocused = true"
            />
          </div>
          <client-only>
            <AppHeaderSearchResultMenu
              v-if="isFocused"
              :query="query"
              container-class="absolute w-full bg-white px-2.5 pb-3 pt-0 rounded-b-md shadow-md z-10 hidden md:block"
              :data="searchData"
              :loading="loading"
              @is-focused="isFocused = false"
            />
          </client-only>
        </div>
      </div>
      <button
        type="submit"
        class="btn btn-primary relative -ml-px inline-flex uppercase items-center gap-x-1.5 rounded-l-none px-3 py-2 text-xs font-bold"
        :class="{ 'z-10': isFocused, [buttonClass]: buttonClass }"
        :aria-label="t('Знайти')"
      >
        {{ t('Знайти') }}
      </button>
    </form>
    <client-only>
      <AppHeaderSearchResultMenu
        v-if="isFocused || alwaysShowResult"
        :data="searchData"
        :query="query"
        :loading="loading"
        :container-class="resultContainerClass"
        @is-focused="isFocused = false"
      />
      <div
        v-if="isFocused"
        class="fixed bg-black/20 inset-0 z-1 hidden md:block"
      ></div>
    </client-only>
  </div>
</template>

<script setup>
import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid'
import debounce from 'lodash/debounce'

const { t, locale } = useI18n()
defineProps({
  autoFocus: {
    type: Boolean,
    default: false
  },
  inputClass: {
    type: String,
    default: ''
  },
  buttonClass: {
    type: String,
    default: ''
  },
  alwaysShowResult: {
    type: Boolean,
    default: false
  },
  resultContainerClass: {
    type: String,
    default: ''
  }
})

const query = ref('')
const loading = ref(false)
const isFocused = ref(false)
const inputRef = ref(null)

const route = useRoute()

const searchData = ref({})

const search = async () => {
  const { data: ipData } = await useFetch('/api/get-ip')
  try {
    const res = await useFetch(
      `https://api.multisearch.io/?id=${
        useRuntimeConfig().public.multisearchSiteId
      }&query=${
        query.value
      }&autocomplete=true&fields=name,price,picture,url&categories=6&limit=3&offset=0&group=true&lang=${
        locale.value
      }`,
      {
        headers: {
          'x-forwarded-for': ipData.value?.ip
        }
      }
    )
    searchData.value = res.data
    useNuxtApp().$gtagEvent.searchPopUpShow(
      query.value,
      res?.data?.value?.total
    )
    if (query.value && !res?.data?.value?.total) {
      useNuxtApp()?.$eSputnikEvent?.searchRequest(query.value)
    }
  } catch (err) {
    console.log('err', err)
  }
}

watch(
  query,
  debounce(() => {
    search()
  }, 700)
)

watch(
  () => route.fullPath,
  () => {
    isFocused.value = false
  },
  { immediate: true }
)

onMounted(() => {
  isFocused.value = false
})

// watch(inputRef, () => {
//   props.autoFocus && inputRef.value && inputRef.value.focus()
// })

const target = ref(null)
onClickOutside(target, () => (isFocused.value = false))

const router = useRouter()
const onSubmitSearch = e => {
  e.preventDefault()
  if (!query.value) {
    return false
  }
  isFocused.value = false
  router.push({
    path: useLocalePath()('/search/'),
    query: { searchstring: query.value }
  })
}
</script>
