<template>
  <div v-if="socials.length" class="flex gap-2.5 max-w-[180px] flex-wrap">
    <a
      v-for="item in socials"
      :key="item.propertyName"
      :href="item.propertyValue"
      rel="nofollow"
      target="_blank"
      class="rounded-full w-8 h-8"
    >
      <component :is="icons[item.propertyName] || icons.default" />
    </a>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { useSettingsStore } from '~/stores/settings'

const { socials } = storeToRefs(useSettingsStore())
const icons = shallowRef({
  facebook: resolveComponent('IconsSocialsFacebook'),
  tiktok_1: resolveComponent('IconsSocialsTiktok'),
  instagram: resolveComponent('IconsSocialsInstagram'),
  youtube: resolveComponent('IconsSocialsYoutube'),
  telegram: resolveComponent('IconsSocialsTelegram'),
  default: resolveComponent('IconsSend')
})
</script>
