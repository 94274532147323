<template>
  <NuxtLink
    v-for="subChild in subChildList(props.child.children)"
    :key="subChild.name"
    :to="
      ProductVariantEntity.buildCatalogCategoryLink({
        categorySlug,
        subCategorySlug: subChild.slug,
        hostname: subChild.channelHostname,
        locale,
        relationSlug: props.relationSlug
      })
    "
    class="hover:text-primary hover:cursor-pointer"
  >
    <span class="block text-wrap">{{ subChild.name }}</span>
  </NuxtLink>
  <button
    v-if="props.child.children.length > 5 && props.isShowMore && !showAll"
    :key="'show-more-' + props.child.name"
    class="link-mud-underline text-left"
    :aria-label="t('Показати ще')"
    @click.prevent="showAll = true"
  >
    {{ t('Показати ще') }}
  </button>
</template>

<script setup>
import ProductVariantEntity from '~/entities/ProductVariant.entity'

const { t, locale } = useI18n()

const props = defineProps({
  child: {
    type: Object,
    default: () => {}
  },
  isShowMore: {
    type: Boolean,
    default: () => false
  },
  trigger: {
    type: Boolean,
    default: () => false
  },
  relationSlug: {
    type: String,
    default: () => ''
  }
})

const categorySlug = inject('categorySlug')

const showAll = ref(false)

watch(
  () => props.trigger,
  () => {
    showAll.value = false
  }
)

const subChildList = subList => {
  return props.isShowMore && !showAll.value ? subList.slice(0, 5) : subList
}
</script>
