<template>
  <button class="" @click="showSearch">
    <slot>
      <MagnifyingGlassIcon
        class="h-5 w-5 text-green-swamp"
        aria-hidden="true"
      />
      {{ t('Пошук') }}
    </slot>
    <template v-if="breakpoints && breakpoints.smaller('md').value">
      <TransitionRoot as="template" :show="show">
        <Dialog as="div" @close="closeSearch">
          <AppDialogsCommonPanelTransition>
            <DialogPanel
              class="container fixed top-0 bottom-0 right-0 left-0 z-10 bg-white flex flex-col overflow-y-hidden"
            >
              <div class="relative min-h-[60px] bg-white z-30">
                <AppDialogsCommonCloseButton
                  class="fixed right-5 top-5 z-30"
                  @click="closeSearch"
                />
              </div>

              <div class="flex-1 overflow-y-auto">
                <AppHeaderSearch
                  :auto-focus="ref(true)"
                  :always-show-result="true"
                  result-container-class="shadow-none "
                />
              </div>
            </DialogPanel>
          </AppDialogsCommonPanelTransition>
        </Dialog>
      </TransitionRoot>
    </template>
  </button>
</template>

<script setup>
import { Dialog, DialogPanel, TransitionRoot } from '@headlessui/vue'
import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid'
const { t } = useI18n()
const breakpoints = useTailwindBreakpoints()
const route = useRoute()

const show = ref(false)

const showSearch = () => {
  show.value = true
}

const closeSearch = () => {
  show.value = false
}

watch(
  () => route.fullPath,
  () => {
    show.value = false
  }
)
</script>
