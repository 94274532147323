<template>
  <Menu
    as="div"
    class="sm:relative text-left h-5 md:h-[58px] py-4"
    @mouseleave="setMouseOver(false)"
    @mouseover="setMouseOver(true)"
  >
    <MenuButton
      :aria-label="t('Порівняння')"
      class="link-green"
      @click="toggleMenu"
    >
      <IconsComparison
        :count="comparisonList?.length || 0"
        class="h-5 md:h-[26px] max-w-[26px]"
      />
    </MenuButton>

    <app-transitions-dropdown>
      <div v-if="open">
        <MenuItems
          static
          class="absolute left-5 right-5 w-auto -translate-x-17 sm:left-0 md:left-auto md:right-0 md:translate-x-0 z-10 shadow-[0_64px_134px_rgba(0,0,0,0.1)] mt-2 sm:w-56 origin-top-right rounded-md bg-white focus:outline-none min-w-[300px]"
        >
          <div class="py-[26px] px-[30px] gap-3 flex flex-col">
            <p class="text-[15px]] lead font-bold mb-1">
              {{ t('Список порівняння') }}
            </p>
            <template
              v-for="comparison in comparisonTaxons"
              :key="comparison.code"
            >
              <MenuItem>
                <div class="flex">
                  <NuxtLink
                    :id="`comparison-menu-link-to-list-${comparison.code}`"
                    :to="localePath(`/compare?t=${comparison.code}`)"
                    class="link flex-1"
                  >
                    <div
                      :class="['group flex items-start text-sm py-1']"
                      class="flex items-start"
                      @click.passive="hideMenu"
                    >
                      <span class="mr-[5px] w-[160px] max-w-[160px] p-0">{{
                        comparison.name
                      }}</span>
                      <span class="text-primary ml-5 p-0">{{
                        comparison.count
                      }}</span>
                    </div>
                  </NuxtLink>
                  <IconsDelete
                    :id="`comparison-menu-delete-list-btn-${comparison.code}`"
                    class="link min-w-[17px] ml-2 mt-[5px]"
                    @click.stop.prevent="removeComparison(comparison.code)"
                  />
                </div>
              </MenuItem>
            </template>
          </div>
        </MenuItems>
      </div>
    </app-transitions-dropdown>

    <AppBackdrop :show="open" />
  </Menu>
</template>

<script setup>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { watchDebounced } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import AppTransitionsDropdown from '../transitions/Dropdown.vue'
import { useComparisonStore } from '~/stores/comparison'

const { t } = useI18n()
const comparisonStore = useComparisonStore()
const { removeComparison } = comparisonStore
const { comparisonTaxons, comparisonList } = storeToRefs(comparisonStore)

const open = ref(false)
const mouseOver = ref(false)
const setMouseOver = val => {
  mouseOver.value = val
}
const toggleMenu = () => {
  open.value = !open.value
}

const hideMenu = () => {
  open.value = false
}

watchDebounced(
  mouseOver,
  v => {
    if (!v) {
      hideMenu()
    }
  },
  { debounce: 200 }
)
</script>
