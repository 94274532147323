<template>
  <div
    class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 wrap gap-x-5 text-sm w-max mt-2 ml-2"
  >
    <div
      v-for="item in props.items"
      :key="item.category.id"
      class="max-w-[340px] mb-4"
    >
      <div
        class="mb-3 text-center border-b border-gray flex items-center pb-1 justify-center"
      >
        {{ item.category.name }}
        <span class="text-xs ml-1"> ({{ item.category.count }}) </span>
        <nuxt-link
          v-if="item.category.id !== '0'"
          :id="`srch-category-${item.category.id}`"
          :to="
            localePath(
              `/search/?searchstring=${item.category.name}&cat_id=${item.category.id}`
            )
          "
          class="bg-gray-200 hover:bg-gray-500 hover:text-white rounded-3xl p-1 ml-2 cursor-pointer"
        >
          <IconsArrowRight class="w-3" />
        </nuxt-link>
        <nuxt-link
          v-else
          :id="`srch-category-all`"
          :to="localePath(`/search/?searchstring=${props.query}`)"
          class="bg-gray-200 hover:bg-gray-500 hover:text-white rounded-3xl p-1 ml-2 cursor-pointer"
        >
          <IconsArrowRight class="w-3" />
        </nuxt-link>
      </div>
      <div v-for="product in item.items" :key="product.id" class="flex mb-3">
        <SearchHeaderResultProduct :product="product" />
      </div>
    </div>
  </div>
</template>

<script setup>
const localePath = useLocalePath()
const props = defineProps({
  items: {
    type: Array,
    default: () => []
  },
  query: {
    type: String,
    default: ''
  }
})
</script>
