<template>
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="1.25"
      y="1.25"
      width="6.25"
      height="6.25"
      rx="1"
      stroke="currentColor"
      stroke-width="2"
    />
    <rect
      x="1.25"
      y="12.5"
      width="6.25"
      height="6.25"
      rx="1"
      stroke="currentColor"
      stroke-width="2"
    />
    <rect
      x="12.5"
      y="1.25"
      width="6.25"
      height="6.25"
      rx="1"
      stroke="currentColor"
      stroke-width="2"
    />
    <rect
      x="12.5"
      y="12.5"
      width="6.25"
      height="6.25"
      rx="1"
      stroke="currentColor"
      stroke-width="2"
    />
  </svg>
</template>
