<template>
  <div class="flex flex-wrap gap-x-3 gap-y-2">
    <nuxt-link
      v-if="props.direct"
      :to="props.direct"
      class="flex items-center gap-x-2 bg-gray-200 hover:text-green-ibis rounded-2xl px-2 py-1 text-xs cursor-pointer"
    >
      <LightBulbIcon class="h-3 w-3 text-primary" aria-hidden="true" />
      <div>{{ t('Популярний результат') }}</div>
    </nuxt-link>
    <nuxt-link
      v-for="suggest in props.suggests"
      :id="`srch-query-${camelCase(transformSuggest(suggest))}`"
      :key="suggest"
      :to="localePath(`/search/?searchstring=${transformSuggest(suggest)}`)"
      class="flex items-center gap-x-2 bg-gray-200 hover:text-green-ibis rounded-2xl px-2 py-1 text-xs cursor-pointer"
    >
      <MagnifyingGlassIcon
        class="h-3 w-3 text-green-swamp"
        aria-hidden="true"
      />
      <div v-html="suggest"></div>
    </nuxt-link>
  </div>
</template>

<script setup>
import camelCase from 'lodash/camelCase'
import { MagnifyingGlassIcon, LightBulbIcon } from '@heroicons/vue/20/solid'

const { t } = useI18n()

const localePath = useLocalePath()
const props = defineProps({
  suggests: {
    type: Array,
    default: () => []
  },
  direct: {
    type: String,
    default: ''
  }
})

const transformSuggest = suggest => {
  return suggest.replaceAll('<b>', '').replaceAll('</b>', '')
}
</script>
