<template>
  <TransitionRoot as="template" :show="true">
    <Dialog as="div" class="relative z-10" @close="close">
      <AppDialogsCommonBackdrop />
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex md:max-h-fit max-h-fit justify-center text-center p-0">
          <AppDialogsCommonPanelTransition>
            <DialogPanel
              class="text-black/80 transform rounded-0 bg-white p-3.5 my-0 text-left transition-all min-h-fit w-full relative md:my-44 md:rounded-md md:w-[750px] md:p-5"
            >
              <div class="flex mb-4 text-center">
                <h2 class="text-sm md:text-xl font-bold w-full">
                  {{ t('Дякуємо за передзамовлення') }} №{{ preorderNumber }}
                </h2>
                <AppDialogsCommonCloseButton class="ml-auto" @click="close" />
              </div>

              <div class="flex flex-col gap-5 text-center">
                <p>
                  {{
                    t(
                      `Найближчим часом наш консультант зв'яжеться з Вами для підтвердження замовлення.`
                    )
                  }}
                </p>
                <p>{{ t('Дякуємо за ваш вибір!') }}</p>
              </div>
            </DialogPanel>
          </AppDialogsCommonPanelTransition>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { Dialog, DialogPanel, TransitionRoot } from '@headlessui/vue'
import { usePreorderStore } from '~/stores/preorder'

const { t } = useI18n()

const preorderNumber = computed(() => usePreorderStore().preorderOrderNumber)

const close = () => {
  usePreorderStore().setModal(false)
}
</script>
