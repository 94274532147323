<template>
  <button :aria-label="t('Закрити')" class="nav-mobile-menu" @click="openMenu">
    <IconsMenuClosed class="h-[17px] w-[17px] text-gray-mud" />
    <p class="nav-mobile-menu--subtitle">
      {{ t('Меню') }}
    </p>
  </button>
  <TransitionRoot as="template" :show="open">
    <Dialog
      as="div"
      class="relative z-10 block md:hidden text-green-swamp"
      @close="open = false"
    >
      <TransitionChild
        as="template"
        enter="ease-in-out duration-500"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in-out duration-500"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div
            class="pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-10"
          >
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="-translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="-translate-x-full"
            >
              <DialogPanel
                class="pointer-events-auto relative w-screen max-w-[290px] -translate-x-full"
              >
                <TransitionChild
                  as="template"
                  enter="ease-in-out duration-500"
                  enter-from="opacity-0"
                  enter-to="opacity-100"
                  leave="ease-in-out duration-500"
                  leave-from="opacity-100"
                  leave-to="opacity-0"
                >
                  <div class="absolute top-0 right-0 -mr-8 flex pt-4 pl-4">
                    <button
                      type="button"
                      class="rounded-md text hover:text-white focus:outline-none"
                      @click="open = false"
                    >
                      <span class="sr-only">Close panel</span>
                      <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </TransitionChild>
                <AppHeaderMenuMobileMain
                  v-if="menu === 'main'"
                  @show-catalog="menu = 'catalog'"
                  @show-compare="menu = 'compare'"
                />

                <AppHeaderMenuMobileCatalog
                  v-else-if="menu === 'catalog'"
                  @back="menu = 'main'"
                />
                <AppHeaderMenuMobileCompare
                  v-else-if="menu === 'compare'"
                  @back="menu = 'main'"
                />
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'

const { t } = useI18n()

const open = inject('isOpenMenu')
const menu = inject('menuType')

const openMenu = () => {
  open.value = true
}
</script>

<style lang="postcss" scoped>
.nav-mobile-menu {
  @apply flex flex-col items-center gap-1;
}

.nav-mobile-menu--subtitle {
  @apply text-[10px] leading-[10px] 2xs:inline-block hidden;
}
</style>
