<template>
  <button
    id="btn-modal-cart-open"
    class="link-green"
    @click="cartStore.openCart"
  >
    <IconsCart class="h-[23px] w-[23px]" :count="count" />
  </button>
</template>

<script setup>
import { useCartStore } from '~~/stores/cart'

const cartStore = useCartStore()

const count = computed(() => cartStore.countItemsInCart)
</script>
