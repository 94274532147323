<template>
  <nav class="md:border-b border-gray">
    <AppHeaderNavDesktop />
    <AppHeaderNavMobile
      :is-show-search="isShowSearch"
      :set-is-show-search="setIsShowSearch"
    />

    <div ref="intersectionTarget" class="w-full"></div>

    <AppTransitionsSlideUp>
      <AppHeaderNavMobile
        v-if="!isVisibleTarget"
        class="fixed bottom-0 bg-white border-t border-gray isolate z-10"
        :is-show-search="isShowSearch"
        :set-is-show-search="setIsShowSearch"
        bottom
      />
    </AppTransitionsSlideUp>
  </nav>
</template>

<script setup>
const intersectionTarget = ref(null)
const isVisibleTarget = ref(true)

useIntersectionObserver(intersectionTarget, ([{ isIntersecting }]) => {
  isVisibleTarget.value = isIntersecting
})

const isShowSearch = ref(false)
const setIsShowSearch = value => {
  isShowSearch.value = value
}

const route = useRoute()

watch(
  () => route.fullPath,
  () => setIsShowSearch(false)
)
</script>
