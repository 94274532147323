<template>
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      cx="9.92704"
      cy="9.92701"
      r="8.90532"
      stroke="currentColor"
      stroke-width="2"
    />
    <circle
      cx="9.92703"
      cy="8.49429"
      r="3.18752"
      stroke="currentColor"
      stroke-width="2"
    />
    <path
      d="M15.5917 17.3464C15.5917 14.218 13.0556 11.6819 9.92719 11.6819C6.79877 11.6819 4.2627 14.218 4.2627 17.3464"
      stroke="currentColor"
      stroke-width="2"
    />
  </svg>
</template>
