import { storeToRefs } from 'pinia'
import debounce from 'lodash/debounce'
import { useAuthStore } from '~/stores/auth'
import { useCartStore } from '~/stores/cart'
import { SC_TOKEN_COOKIE_KEY } from '~/utils/constants'

export default function () {
  if (import.meta.server) {
    return false
  }
  const authStore = useAuthStore()
  const cartStore = useCartStore()

  const storeType = useStoreType()
  const {
    token,
    refresh,
    customerId,
    tokenStore,
    refreshStore,
    rememberCustomer
  } = storeToRefs(authStore)
  const cartToken = computed(() => cartStore.cartToken)
  const scCookie = useCookie(SC_TOKEN_COOKIE_KEY, { watch: true })
  const sync = debounce(() => {
    refreshCookie(SC_TOKEN_COOKIE_KEY)
    try {
      const host = storeType.isHunting
        ? storeType.fishingDomain
        : storeType.huntingDomain
      let url
      if (authStore.loggedIn) {
        url = `${host}/sync-cookies/?token=${token.value || ''}&refresh=${
          refresh.value || ''
        }&customerId=${
          customerId.value || ''
        }&tokenStore=&refreshStore=&rememberCustomer=${
          rememberCustomer.value ?? ''
        }&cartToken`
      } else if (authStore.loggedInStore) {
        url = `${host}/sync-cookies/?token=&refresh=&customerId=&tokenStore=${
          tokenStore.value || ''
        }&refreshStore=${refreshStore.value || ''}&rememberCustomer=${
          rememberCustomer.value ?? ''
        }&cartToken=${cartToken.value || ''}&scToken=${scCookie.value || ''}`
      } else {
        url = `${host}/sync-cookies/?token=&refresh=&customerId=&tokenStore=&refreshStore=&rememberCustomer=&cartToken=${
          cartToken.value || ''
        }&scToken=${scCookie.value || ''}`
      }
      // $fetch(url, { mode: 'no-cors' }).catch(e => {
      //   console.log(e)
      // })
      // return true
      if (!document) {
        return false
      }
      setTimeout(() => {
        const iframe = document.createElement('iframe')
        iframe.style.display = 'none'
        iframe.id = 'syncCookiesID'
        iframe.setAttribute('src', url)
        iframe.onload = () => {
          // console.log('iframe', iframe)
          // console.log('iframe.window', iframe.window)
          // console.log('iframe.contentWindow', iframe.contentWindow)
          // iframe.contentWindow.closeIframe = () => { console.log('close iframe MTF') }
          // iframe.contentWindow.closeIframe = () => { console.log('close iframe MTF') }
          // setTimeout(() => {
          //   try {
          //     iframe && iframe.remove()
          //   } catch (e) {
          //     console.log('error on delete iframe')
          //   }
          // }, 10000)
        }
        document.getElementsByTagName('body')[0].appendChild(iframe)
        window.addEventListener(
          'message',
          event => {
            if (event.data === 'closeSyncCookiesWindow') {
              iframe.remove()
            }
          },
          {
            once: true
          }
        )
      }, 500)
    } catch (e) {
      console.log('sync error')
      console.error(e)
    }
  }, 1000)
  if (scCookie.value) {
    sync()
  }
  watch(() => scCookie.value, sync)
  watch(token, sync)
  watch(tokenStore, sync)
  watch(cartToken, () => {
    if (!authStore.loggedIn) {
      sync()
    }
  })
}
