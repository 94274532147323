<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="close">
      <AppDialogsCommonBackdrop />
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex md:max-h-fit max-h-fit justify-center text-center p-0">
          <AppDialogsCommonPanelTransition>
            <DialogPanel
              class="text-black/80 transform rounded-0 bg-white p-3.5 my-0 text-left transition-all min-h-fit w-full relative md:my-44 md:rounded-md md:w-[750px] md:p-5"
            >
              <div class="flex mb-4">
                <h2 class="text-sm md:text-xl font-bold">
                  {{ t('Повідомити автору про помилку') }}
                </h2>
                <AppDialogsCommonCloseButton class="ml-auto" @click="close" />
              </div>

              <div class="flex flex-col gap-5">
                <div class="text-xs">
                  <div class="font-bold mb-1">
                    {{ t('Адреса сторінки з помилкою') }}:
                  </div>
                  <span class="text-primary underline">{{ data?.link }}</span>
                </div>
                <div class="text-xs">
                  <div class="font-bold mb-1">
                    {{ t('Текст з помилкою') }}:
                  </div>
                  <span v-html="data?.selected"></span>
                </div>
                <div class="text-xs">
                  <div class="font-bold mb-1">
                    {{ t('Текст з помилкою') }}:
                  </div>
                  <AppFormInput
                    v-model="text"
                    class="!mt-0"
                    type="text"
                    :placeholder="t('Повідомлення автору про помилку')"
                  />
                </div>
                <div class="flex justify-start h-[40px] mb-1">
                  <button
                    class="btn-primary uppercase px-8 flex items-center"
                    @click="submit"
                  >
                    {{ t('Відправити') }}
                  </button>
                </div>
              </div>
            </DialogPanel>
          </AppDialogsCommonPanelTransition>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { Dialog, DialogPanel, TransitionRoot } from '@headlessui/vue'
const { $toast } = useNuxtApp()
const { t } = useI18n()
const emits = defineEmits(['update:modelValue'])
const open = ref(false)
const data = ref({})
const text = ref('')
const isFirstOpen = ref(true)

const close = () => {
  if (isFirstOpen.value) {
    isFirstOpen.value = false
    return false
  }
  open.value = false
}

const submit = () => {
  return useApi().textMistakes.sendMistake(
    {
      siteUrl: data.value?.link,
      textMistake: data.value?.selected,
      userComment: text.value
    },
    {
      onSuccess: () => {
        $toast.success(t('Повідомлення відправлено'))
      },
      onFinally: () => {
        close()
      }
    }
  )
}

onMounted(() => {
  emits('update:modelValue', {
    open: v => {
      isFirstOpen.value = true
      text.value = ''
      open.value = true
      data.value = v
    }
  })
})
</script>
