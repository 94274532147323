<template>
  <div
    v-for="category in categories"
    :key="category.id"
    class="flex text-xs border-b border-gray hover:bg-gray-200 p-2 pr-5 justify-between items-center min-h-[10px] cursor-pointer"
    :class="category.id === selectedCategory ? 'bg-gray-200' : ''"
    @click="emit('selectCategory', category)"
  >
    <div class="flex break-words">
      <FolderOpenIcon
        v-if="category.id === selectedCategory"
        class="w-3 mr-2 text-gray-400"
      />
      <FolderIcon v-else class="w-3 mr-2 text-gray-400" />
      {{ category.name }}
      <IconsArrowRight class="w-3 ml-2 text-green-ibis" />
    </div>
    <div>
      {{ category.count }}
    </div>
  </div>
</template>

<script setup>
import { FolderIcon, FolderOpenIcon } from '@heroicons/vue/20/solid'

defineProps({
  categories: {
    type: Array,
    default: () => []
  },
  selectedCategory: {
    type: Number,
    default: () => 0
  }
})

const emit = defineEmits('selectCategory')
</script>
