<template>
  <div :class="getContainerClass">
    <div ref="target" class="space-y-2.5 border-t border-gray py-2.5">
      <SearchHeaderTheSuggest
        :suggests="suggests"
        :direct="directLink"
        class="mt-1 mb-3"
      />
      <client-only>
        <div v-if="filteredItems.length" class="md:flex justify-between mt-3">
          <div class="block md:flex flex-col w-full md:!max-w-[324px]">
            <SearchHeaderTheCategories
              :categories="categories"
              :selected-category="selectedCategoryId"
              @select-category="selectCategory"
            />
            <SearchHeaderTheBrands :brands="brands" class="mt-7" />
          </div>
          <div
            class="md:grid md:col-span-12 mt-7 md:mt-0 w-full search-result-items overflow-hidden overflow-y-auto"
          >
            <SearchHeaderResultItems
              :items="filteredItems"
              :query="props.query"
            />
          </div>
        </div>
      </client-only>
    </div>
  </div>
</template>

<script setup>
import { twMerge } from 'tailwind-merge'

const { t } = useI18n()

const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  },
  onClose: {
    type: Function,
    default: () => {}
  },
  containerClass: {
    type: String,
    default: ''
  },
  query: {
    type: String,
    default: ''
  },
  loading: {
    type: Boolean,
    default: () => false
  }
})

// const emit = defineEmits(['isFocused'])

const allCategories = computed(() => {
  if (!props.data?.value?.results?.categories?.length) {
    return {
      count: 0,
      id: '0',
      name: t('Всі категорії')
    }
  }
  const totalCount = props.data?.value?.results?.categories?.reduce(
    (accumulator, currentItem) => {
      return accumulator + currentItem.count
    },
    0
  )
  return {
    count: totalCount,
    id: '0',
    name: t('Всі категорії')
  }
})

const allItems = computed(() => {
  if (!props.data?.value?.results?.items) {
    return {}
  }
  const items = props.data?.value?.results?.items.map(item => item.items[0])
  return {
    category: allCategories.value,
    items: items?.slice(0, 4)
  }
})

const brands = computed(() => {
  return props.data?.value?.results?.brands || []
})

const categories = computed(() => {
  if (props.data?.value?.results?.categories?.length) {
    return (
      [allCategories.value, ...props.data?.value?.results?.categories] || []
    )
  }
  return []
})

const items = computed(() => {
  if (props.data?.value?.results?.items?.length) {
    return [allItems.value, ...props.data?.value?.results?.items] || []
  }
  return []
})

const suggests = computed(() => {
  return props.data?.value?.results?.suggest || []
})

const directLink = computed(() => {
  if (props.data?.value?.direct) {
    return props.data?.value?.direct
  }
  return false
})

const selectedCategoryId = ref(0)

const filteredItems = computed(() => {
  if (selectedCategoryId.value === 0) {
    return items.value
  }
  return items.value?.filter(
    item => item.category.id === selectedCategoryId.value
  )
})

const selectCategory = category => {
  selectedCategoryId.value = category.id
}

const getContainerClass = computed(() => {
  return twMerge(
    'bg-white px-2.5 py-3 rounded-b-md shadow-md z-10 block md:hidden w-full',
    props.containerClass
  )
})
</script>

<style scoped>
.search-result-items {
  max-height: 70vh;
}
</style>
