<template>
  <Html :lang="head?.htmlAttrs?.lang" :dir="head?.htmlAttrs?.dir">
    <Head>
      <Title>
        {{ title || pageSeoData?.title || (t && t('default_seo_title')) || '' }}
      </Title>
      <Link
        v-if="headLinks"
        id="canonical-url"
        rel="canonical"
        :href="canonical"
      />
      <template v-if="!isShowAlternate && headLinks">
        <Link
          id="x-default"
          rel="alternate"
          :href="defaultAlternate"
          hreflang="x-default"
        />
        <Link
          id="ua-alternate"
          rel="alternate"
          :href="defaultAlternate"
          hreflang="uk"
        />
        <Link
          id="ru-alternate"
          rel="alternate"
          :href="ruAlternate"
          hreflang="ru"
        />
      </template>
    </Head>
    <Body>
      <div class="contents">
        <TheHeader />
        <div id="mainContentWrapper" class="flex flex-col flex-1">
          <main class="flex-1">
            <slot></slot>
          </main>
          <TheFooter :key="`footer-${lang}`" />
        </div>
        <client-only>
          <AppDialogsCallBack v-model="dialog" />
          <AppDialogsAuth />
          <AppDialogsCtrlEnterErrorModal v-model="ctrlEnterModal" />
          <PreorderSuccessModal v-if="isOpenPreorderSuccessModal" />
          <LazyAppCart />
        </client-only>
      </div>
    </Body>
  </Html>
</template>

<script setup>
import trimEnd from 'lodash/trimEnd'
import { useMenuStore } from '~~/stores/menu'
import { useSettingsStore } from '~/stores/settings'
import { usePreorderStore } from '~/stores/preorder'
defineProps({
  headLinks: {
    type: Boolean,
    default: true
  },
  title: {
    type: String,
    default: ''
  }
})
const { t } = useI18n()
const route = useRoute()
const reqURL = useRequestURL()
const storeType = useStoreType()
const head = useLocaleHead({
  addDirAttribute: false,
  identifierAttribute: 'id',
  addSeoAttributes: true
})

const lang = computed(() => useNuxtApp()?.$i18n?.locale?.value || 'uk')

const pageSeoData = computed(() => useSeoStore().seoData || {})
// const { data: pageSeoData } =
//     await useAsyncData('seo-page-data-' + route.fullPath,
//       () => useApi().seo.getByReferer(),
//       {
//         default: () => {},
//         watch: [() => route.fullPath, lang]
//       }
//     )

const host = computed(() => {
  return storeType.isHunting ? storeType.huntingDomain : storeType.fishingDomain
})

const canonical = computed(() => {
  if (pageSeoData.value?.canonical) {
    return `${host.value}/${pageSeoData.value?.canonical}`
  }
  return `${trimEnd(host.value, '/')}/`
})

const ruAlternate = computed(() => {
  if (lang.value === 'ru') {
    return `${host.value}${route.path}`
  }
  return `${host.value}/ru${route.path}`
})

const defaultAlternate = computed(() =>
  `${host.value}${route.path}`.replace('/ru/', '/')
)
const isShowAlternate = computed(() => !!Object.keys(route.query).length)

// watch(() => route.path, () => useSeoStore().setSeoData({}))

const dialog = ref(null)
provide('callBackDialog', dialog)

useAuthSync()
useTrackers()

useLazyAsyncData(
  'menu-categories-' + lang.value,
  () => useMenuStore().fetchRootCategoriesOnce(lang.value),
  { watch: [lang] }
)

useLazyAsyncData(
  'top-categories-' + lang.value,
  () => useMenuStore().fetchTopCategories(lang.value),
  {
    watch: [lang]
  }
)

useLazyAsyncData('settings-data', () => useSettingsStore().fetchSettings())

useLazyAsyncData('project-settings', () =>
  useSettingsStore().fetchProjectsSettings()
)

const isOpenPreorderSuccessModal = computed(
  () => usePreorderStore().modalVisible
)

watch(
  () => route.path,
  () => {
    process.client &&
      eS('sendEvent', 'PageView', {
        PageView: {}
      })
  }
)
// copy logic
const handleCopyEvent = event => {
  const selection = window.getSelection()
  const copiedText = selection.toString().trim()

  if (copiedText) {
    const selectedElement = selection?.anchorNode?.parentElement

    if (
      selectedElement?.id === 'variant-title' ||
      selectedElement?.id === 'variant-code' ||
      event?.target?.dataset?.copyProtection === '0'
    ) {
      return false
    }

    const additionalText = `\n\n\n Детальніше на ${reqURL.origin} \n ${
      reqURL.origin + route.path
    }`
    const newText = copiedText + additionalText

    event.clipboardData.setData('text/plain', newText)

    event.preventDefault()
  }
}

// ctrl + enter logic
const ctrlEnterModal = ref(null)

const openCtrlEnterModal = data => {
  ctrlEnterModal.value?.open(data)
}

const handleCtrlEnter = event => {
  if (event.ctrlKey && event.key === 'Enter') {
    const selection = window.getSelection().toString().trim()
    if (!selection || selection.rangeCount === 0) {
      return false
    }
    const pageURL = window.location.href

    openCtrlEnterModal({ link: pageURL, selected: selection })
  }
}
onMounted(() => {
  if (process.client) {
    document.addEventListener('copy', handleCopyEvent)
    document.addEventListener('keydown', handleCtrlEnter)
  }
})

onUnmounted(() => {
  if (process.client) {
    document.removeEventListener('copy', handleCopyEvent)
    document.removeEventListener('keydown', handleCtrlEnter)
  }
})

try {
  useSchemaOrg([
    {
      '@type': 'Organization',
      name: useStoreType().isHunting
        ? '«Ібіс» - зброя та полювання'
        : '«Ібіс» - рибальство та туризм',
      logo: new URL(
        '/assets/images/ibis_blogo.jpg',
        useRuntimeConfig().public.originDomain
      )?.href,
      image: new URL(
        '/assets/images/ibis_blogo.jpg',
        useRuntimeConfig().public.originDomain
      )?.href,
      url: useRuntimeConfig().public.originDomain,
      // email: ['webstore@sporting.com.ua', 'fishing.ibis@gmail.com'],
      // telephone: '0 800 600 002',
      contactPoint: [
        {
          '@type': 'ContactPoint',
          telephone: '0 800 600 002'
        },
        {
          '@type': 'ContactPoint',
          email: 'webstore@sporting.com.ua'
        },
        {
          '@type': 'ContactPoint',
          email: 'fishing.ibis@gmail.com'
        }
      ]
    },
    {
      '@type': 'WebSite',
      name: useStoreType().isHunting
        ? '«Ібіс» - зброя та полювання'
        : '«Ібіс» - рибальство та туризм',
      url: useRuntimeConfig().public.originDomain,
      potentialAction: {
        '@type': 'SearchAction',
        target: new URL(
          '/search/?searchstring={search_term_string}',
          useRuntimeConfig().public.originDomain
        )?.href,
        'query-input': 'required name=search_term_string'
      }
    }
  ])
} catch (e) {
  console.log('useSchemaOrg error', e)
}
</script>
