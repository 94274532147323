<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.08621 1.875L8 7.89255M8 7.89255L14.125 14.125M8 7.89255L1.875 14.125M8 7.89255L13.9138 1.87501"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>
